import React from "react"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Films from "../components/Films"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"

const FilmsPage = () => {
  return (
    <>
      <Layout>
        <Navbar title={`Film & TV`} dark />
        <Films />
        <ContactForm dark />
        <Footer dark />
      </Layout>
    </>
  )
}

export default FilmsPage
